import { defineMessages } from 'react-intl';

export default defineMessages({
	yourPlan: {
		id: 'plan.yourPlan',
		defaultMessage: 'Your plan',
		description: 'Title for the plan section',
	},
	planInfo: {
		id: 'plan.planInfo',
		defaultMessage: '<boldtext>Premium</boldtext> | {lives} covered lives at {cost}/user/year',
		description: 'Info for the plan section',
	},
	paymentDetails: {
		id: 'plan.paymentDetails',
		defaultMessage: 'Payment details',
		description: 'Payment details for the plan section',
	},
	renewalCadence: {
		id: 'plan.renewalCadence',
		defaultMessage: 'Renews: {cadence}',
		description: 'Explains how often the plan renews',
	},
	nextDate: {
		id: 'plan.nextDate',
		defaultMessage: 'Next bill date: {billdate}',
		description: 'Next date for the plan section',
	},
	nextSubtotal: {
		id: 'plan.nextSubtotal',
		defaultMessage: 'Next bill subtotal: {billsubtotal}',
		description: 'Next subtotal for the plan section',
	},
	nextTaxes: {
		id: 'plan.nextTaxes',
		defaultMessage: 'Next bill taxes: {billtaxes}',
		description: 'Next taxes for the plan section',
	},
	nextTotal: {
		id: 'plan.nextTotal',
		defaultMessage: 'Next bill total: {billtotal}',
		description: 'Next total for the plan section',
	},
	cancelPlan: {
		id: 'plan.cancelPlan',
		defaultMessage: 'Cancel plan',
		description: 'Cancel plan link for the plan section',
	},
	reactivatePlan: {
		id: 'plan.reactivatePlan',
		defaultMessage: 'Reactivate plan',
		description: 'Reactivate plan link for the plan section',
	},
	cancelPlanRetainAccessDisclaimer: {
		id: 'plan.cancelPlanRetainAccessDisclaimer',
		defaultMessage:
			'If you cancel now, your covered lives will still have access to Calm until {expirationdate}.',
		description: 'Explainer of what happens if they cancel',
	},
	canceledAt: {
		id: 'plan.canceledAt',
		defaultMessage: 'Canceled at: {canceledat}',
		description: 'Telling the user that they have canceled their plan',
	},
	terminatePlan: {
		id: 'plan.terminatePlan',
		defaultMessage: 'Terminate plan',
		description: 'Terminate plan link for the plan section',
	},
	expiresAt: {
		id: 'plan.expiresAt',
		defaultMessage: 'Ends at: {expiresat}',
		description: 'Telling the user when their plan ends',
	},
	subscriptionErrorMessage: {
		id: 'plan.subscriptionErrorMessage',
		defaultMessage:
			'We were unable to load your subscription details. Please contact our support if this error persists.',
		description: 'Error message to display if we are unable to load account details',
	},
	notSelfServe: {
		id: 'plan.notSelfServe',
		defaultMessage: '{partnerName} is not a Self Serve Partner, so Plan & Payment Details are unavailable.',
		description:
			'Message to display if we attempt to load Plan & Payment Details for a non-self-serve partner',
	},
	support: {
		id: 'plan.support',
		defaultMessage:
			'Have a question about managing your subscription, billing or refunding a Stripe credit? {linebreak}You can reach out to our Business Support team <link>here</link>.',
		description: 'Message explaining where to reach out for support on self serve plans',
	},
	flagLoadError: {
		id: 'plan.flagLoadError',
		defaultMessage: 'An error occurred while loading the page. Please try again.',
		description: 'Error message for when feature flags fail to load',
	},
});
