import copy from 'copy-to-clipboard';
import { FC, FormEvent, ReactNode, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { PrimaryButton, Loader, Modal, Button, Text, FormInput } from '@calm-web/design-system';
import useForm, { stringFromModelValue, validation } from '@calm-web/use-form';

import AddAdminModal from '@/components/pages/Account/AdminUsers/AddAdminModal';
import StickSaveButton from '@/components/pages/Account/StickySaveButton';
import CellTitle, { Subtitle } from '@/components/ui/CellTitle';
import { EnvConfig } from '@/env_config';
import { useEligibility } from '@/hooks/api/useEligibility';
import { useFeatureFlags } from '@/hooks/api/useFeatureFlags';
import { useDefinedPartner } from '@/hooks/api/usePartner';
import { usePartnerAdmin } from '@/hooks/api/usePartnerAdmin';
import {
	SftpConfig,
	useAddSftpEmailRecipients,
	useInviteSftpAdmin,
	useSftpConfig,
	useUploadSshKey,
} from '@/hooks/api/useSftpConfig';
import { EditSftpFormProps, useSftpForm, useSftpSubmitData } from '@/hooks/forms/useSftpForm';
import { setBannerMessage } from '@/store/actions/setBannerMessage';

import messages from './messages';
import {
	HelperLink,
	CardStyled,
	Row,
	RowForm,
	FormInputStyled,
	SectionStyled,
	StyledTextArea,
	ButtonContainer,
} from './styles';

const { sftpUrl } = EnvConfig;
const SFTP_DOCS_LINK = '/docs/sftp-instructions';

const SftpAdminInfo: FC<{ shouldUseRedesign: boolean }> = ({ shouldUseRedesign }) => {
	const { formatMessage } = useIntl();

	return (
		<Subtitle>
			{shouldUseRedesign
				? formatMessage(messages.sftpSubtitle, {
						linebreak: <br />,
						link: (...chunks: ReactNode[]) => (
							<HelperLink
								href={`${SFTP_DOCS_LINK}#eligibility-file`}
								target="_blank"
								rel="noopener noreferrer"
							>
								{chunks}
							</HelperLink>
						),
				  })
				: formatMessage(messages.sftpSubtitleOld, {
						sftpDocLink: (...chunks: ReactNode[]) => (
							<HelperLink href={`${SFTP_DOCS_LINK}`} target="_blank" rel="noopener noreferrer">
								{chunks}
							</HelperLink>
						),
				  })}
		</Subtitle>
	);
};

const SftpAdmin: FC<{
	itAdminEmail: string;
	shouldUseRedesign: boolean;
	wholeFormProps: EditSftpFormProps;
}> = ({ itAdminEmail, shouldUseRedesign, wholeFormProps }) => {
	const { formatMessage } = useIntl();
	const formProps = useForm('sftpIntegration', {
		initialModel: { itAdminEmail },
		validation: {
			itAdminEmail: validation.validateOrFail([
				{
					rules: [validation.email],
					errorResult: 'Please enter a valid email',
				},
			]),
		},
	});
	const [showNoMatchModal, setShowNoMatchModal] = useState(false);
	const [showAdminModal, setShowAdminModal] = useState(false);
	const partner = useDefinedPartner();
	const { data: admins = [] } = usePartnerAdmin(partner.id);
	const emailProps = formProps.bindWithErrorProps('itAdminEmail');
	const [inviteAdmin, { loading }] = useInviteSftpAdmin();

	const onSubmit = async (e: FormEvent): Promise<void> => {
		e.preventDefault();
		const email = stringFromModelValue(formProps.model.itAdminEmail);
		if (!email) {
			return;
		}
		const domainRegex = /(?<=@)[^.]+(?=\.)/g;
		const hasMatchingDomain = admins.some(
			({ email: adminEmail }) => adminEmail?.match(domainRegex) === email.match(domainRegex),
		);

		if (admins.length && !hasMatchingDomain) {
			setShowNoMatchModal(true);
		} else {
			await inviteAdmin({ itAdminEmail: email });
		}
	};

	const addAdmin = async (): Promise<void> => {
		const email = stringFromModelValue(formProps.model.itAdminEmail);
		if (email) {
			await inviteAdmin({ itAdminEmail: email });
			setShowNoMatchModal(false);
		}
	};

	const isDisabled = !formProps.validation.isValid || !formProps.dirtyState.itAdminEmail?.hasChanged;
	return !shouldUseRedesign ? (
		<>
			<SectionStyled>
				<SftpAdminInfo shouldUseRedesign={shouldUseRedesign} />
			</SectionStyled>
			<SectionStyled>
				<RowForm onSubmit={onSubmit}>
					<div>
						<FormInputStyled
							{...emailProps}
							data-testid="it-admin-email-input"
							label={formatMessage(messages.itAdminEmailInput)}
						/>
					</div>

					<div>
						<PrimaryButton isDisabled={isDisabled} type="submit" isLoading={loading}>
							{formatMessage(messages.itAdminEmailSubmit)}
						</PrimaryButton>
					</div>
				</RowForm>
			</SectionStyled>
			<Modal
				title="No matching email domain"
				isOpen={showNoMatchModal}
				canClose={false}
				closeModal={() => setShowNoMatchModal(false)}
				footer={
					<ButtonContainer>
						<Button
							onPress={() => setShowNoMatchModal(false)}
							hideShadow
							backgroundColor="transparent"
							textColor="blue3"
						>
							Cancel
						</Button>
						<PrimaryButton onPress={addAdmin}>Add Anyway</PrimaryButton>
					</ButtonContainer>
				}
			>
				<Text el="p">
					The domain of the email address you&apos;ve entered does not match the domain used for any other
					admin.
				</Text>
				<Text el="p" noMargin>
					Do you wish to use the <b>{stringFromModelValue(formProps.model.itAdminEmail)}</b> address for your
					IT Admin?
				</Text>
			</Modal>
		</>
	) : (
		<>
			<SectionStyled>
				<SftpAdminInfo shouldUseRedesign={shouldUseRedesign} />
			</SectionStyled>
			<SectionStyled>
				<PrimaryButton type="button" isLoading={loading} onPress={() => setShowAdminModal(true)}>
					{formatMessage(messages.itAdminEmailSubmit)}
				</PrimaryButton>
			</SectionStyled>
			<AddAdminModal
				partnerId={partner.id}
				showAdminModal={showAdminModal}
				setShowAdminModal={setShowAdminModal}
				isCalmHealth={false}
				isSftpAdmin
			/>
		</>
	);
};

const SshInfo: FC<{ shouldUseRedesign: boolean }> = ({ shouldUseRedesign }) => {
	const { formatMessage } = useIntl();
	return (
		<Subtitle>
			{shouldUseRedesign
				? formatMessage(messages.sshKeyInfo, {
						linebreak: <br />,
						sshKeyLink: (...chunks: ReactNode[]) => (
							<HelperLink href={`${SFTP_DOCS_LINK}#ssh-key`} target="_blank" rel="noopener noreferrer">
								{chunks}
							</HelperLink>
						),
				  })
				: formatMessage(messages.sshKeyInfoOld, {
						linebreak: <br />,
						sshKeyLink: (...chunks: ReactNode[]) => (
							<HelperLink href={`${SFTP_DOCS_LINK}#ssh-key`} target="_blank" rel="noopener noreferrer">
								{chunks}
							</HelperLink>
						),
						formattingLink: (...chunks: ReactNode[]) => (
							<HelperLink
								href={`${SFTP_DOCS_LINK}#eligibility-file`}
								target="_blank"
								rel="noopener noreferrer"
							>
								{chunks}
							</HelperLink>
						),
				  })}
		</Subtitle>
	);
};

const SftpDetails: FC<{ username: string }> = ({ username }) => {
	const dispatch = useDispatch();
	const { formatMessage } = useIntl();
	const formProps = useForm('sftpDetailsForm', {
		initialModel: {
			username: username,
			sftpUrl,
		},
	});

	const copySftpUsername = (): void => {
		copy(username, {
			format: 'text/plain',
			onCopy: () => {
				dispatch(
					setBannerMessage({
						message: 'SFTP username copied to clipboard',
						isError: false,
						flash: true,
					}),
				);
			},
		});
	};

	const copySftpUrl = (): void => {
		copy(sftpUrl, {
			format: 'text/plain',
			onCopy: () => {
				dispatch(
					setBannerMessage({
						message: 'SFTP url copied to clipboard',
						isError: false,
						flash: true,
					}),
				);
			},
		});
	};

	const usernameProps = formProps.bindWithErrorProps('username');
	const sftpUrlProps = formProps.bindWithErrorProps('sftpUrl');

	const sftpUsernameId = 'sftp-username';
	const sftpUrlId = 'sftp-url';

	return (
		<SectionStyled>
			<Row>
				<div>
					<FormInputStyled
						{...usernameProps}
						disabled
						id={sftpUsernameId}
						data-testid="sftp-username"
						label={formatMessage(messages.sftpUsernameLabel)}
					/>
				</div>
				<div>
					<PrimaryButton onPress={copySftpUsername} aria-describedby={sftpUsernameId}>
						{formatMessage(messages.sftpUsernameCopyText)}
					</PrimaryButton>
				</div>
			</Row>
			<Row>
				<div>
					<FormInputStyled
						{...sftpUrlProps}
						disabled
						id={sftpUrlId}
						data-testid="sftp-url"
						label={formatMessage(messages.sftpUrlLabel)}
					/>
				</div>
				<div>
					<PrimaryButton onPress={copySftpUrl} aria-describedby={sftpUrlId}>
						{formatMessage(messages.sftpUrlCopyText)}
					</PrimaryButton>
				</div>
			</Row>
		</SectionStyled>
	);
};

const SshKey: FC<{ formProps: EditSftpFormProps; isConfigured: boolean; shouldUseRedesign: boolean }> = ({
	formProps,
	isConfigured,
	shouldUseRedesign,
}) => {
	const { formatMessage } = useIntl();
	const individualFormProps = useForm('sshKey', {
		initialModel: { sshKey: '' },
		validation: {
			sshKey: validation.validateOrFail([
				{
					rules: [validation.required, validation.minLength(4)],
					errorResult: 'Please enter a valid SSH key.',
				},
			]),
		},
	});

	const [uploadSshKey, { loading }] = useUploadSshKey();

	const sshKeyProps = shouldUseRedesign
		? formProps.bindWithErrorProps('ssh_key')
		: individualFormProps.bindWithErrorProps('sshKey');

	const onSubmit = async (e: FormEvent): Promise<void> => {
		e.preventDefault();
		const sshKey = stringFromModelValue(individualFormProps.model.sshKey);
		if (!sshKey) {
			return;
		}
		await uploadSshKey({ sshKey });
	};

	return (
		<>
			<SectionStyled>
				<SshInfo shouldUseRedesign={shouldUseRedesign} />
			</SectionStyled>
			<SectionStyled>
				{!shouldUseRedesign ? (
					<form onSubmit={onSubmit}>
						<StyledTextArea
							{...sshKeyProps}
							placeholder={isConfigured ? '********' : undefined}
							data-testid="ssh-key-input"
							label={formatMessage(messages.sshKeyInput)}
						/>
						<div>
							<PrimaryButton
								type="submit"
								isDisabled={!individualFormProps.validation.isValid}
								isLoading={loading}
							>
								{formatMessage(messages.sshKeySubmit)}
							</PrimaryButton>
						</div>
					</form>
				) : (
					<>
						<StyledTextArea
							{...sshKeyProps}
							placeholder={isConfigured ? '********' : undefined}
							data-testid="ssh-key-input"
							label={formatMessage(messages.sshKeyInput)}
						/>
					</>
				)}
			</SectionStyled>
		</>
	);
};

const HelpSection: FC<{}> = () => {
	const { formatMessage } = useIntl();

	return (
		<SectionStyled>
			<Subtitle>
				{formatMessage(messages.helpText, {
					link: (...chunks: ReactNode[]) => (
						<HelperLink
							href="https://support.calm.com/hc/en-us?solvvyProvidedByEmployer"
							target="_blank"
							rel="noopener noreferrer"
						>
							{chunks}
						</HelperLink>
					),
				})}
			</Subtitle>
		</SectionStyled>
	);
};

const SftpStatus: FC<{ formProps: EditSftpFormProps }> = ({ formProps }) => {
	const partner = useDefinedPartner();
	const { data: eligibilityData, loading: loadingSyncTime } = useEligibility(partner.id, 0, 1);
	const lastSyncDate = eligibilityData?.last_eligibility_upload?.date
		? new Date(eligibilityData.last_eligibility_upload.date)
		: null;
	const statusEmailsErrorProps = formProps.bindWithErrorProps('email_recipients');
	return (
		<>
			<CellTitle>SFTP Status</CellTitle>
			<SectionStyled>
				<Subtitle>
					{loadingSyncTime
						? 'Loading status...'
						: lastSyncDate
						? `Last sync was ${lastSyncDate.toLocaleString()}.`
						: 'SFTP has not yet been synced.'}
				</Subtitle>
			</SectionStyled>
			<SectionStyled>
				<Subtitle>You can add multiple emails to receive notifications of SFTP errors by email.</Subtitle>
			</SectionStyled>
			<FormInput
				{...statusEmailsErrorProps}
				label="Email for SFTP notifications (comma separated for multiple)"
			/>
		</>
	);
};
export const B2B_SFTP_REDESIGN = 'b2b-sftp-redesign';

const SftpIntegrationInner: FC<{ sftpConfig: SftpConfig; shouldUseRedesign: boolean }> = ({
	sftpConfig,
	shouldUseRedesign,
}) => {
	const { formatMessage } = useIntl();
	const { formProps, hasChangedAny, hasTouchedAny } = useSftpForm(
		sftpConfig.email_recipients?.join(', ') ?? '',
	);
	const { getSftpSubmitData, showValidationErrors } = useSftpSubmitData(formProps);

	const [uploadSshKey, { loading }] = useUploadSshKey();
	const [addEmailRecipients, { loading: isLoading }] = useAddSftpEmailRecipients();

	const onSubmit = async (e: FormEvent): Promise<void> => {
		e.preventDefault();
		if (showValidationErrors()) {
			return;
		}
		const submitData = getSftpSubmitData();
		if (submitData.email_recipients) {
			addEmailRecipients({ email_recipients: submitData.email_recipients })
				.then(async () => {
					if (submitData.ssh_key) {
						await uploadSshKey({ sshKey: submitData.ssh_key });
					}
					formProps.resetAllDirtyStates();
				})
				.catch(() => {});
		}
	};

	const innerForm = (
		<>
			{shouldUseRedesign && (
				<CardStyled>
					<SftpStatus formProps={formProps} />
				</CardStyled>
			)}
			<CardStyled>
				<CellTitle>{formatMessage(messages.sftpTitle)}</CellTitle>
				<SftpAdmin
					wholeFormProps={formProps}
					itAdminEmail={sftpConfig.it_admin_email}
					shouldUseRedesign={shouldUseRedesign}
				/>
				{(sftpConfig.it_admin_email || shouldUseRedesign) && (
					<SshKey
						formProps={formProps}
						isConfigured={!!sftpConfig.sftp_username}
						shouldUseRedesign={shouldUseRedesign}
					/>
				)}
				{sftpConfig.sftp_username && <SftpDetails username={sftpConfig.sftp_username} />}
				<HelpSection />
			</CardStyled>
		</>
	);

	return shouldUseRedesign ? (
		<form onSubmit={onSubmit}>
			{innerForm}
			<StickSaveButton
				isFormDirty={hasChangedAny}
				hasTouchedForm={hasTouchedAny}
				isLoading={loading || isLoading}
				dataTestId="create-edit-health-config-save-btn"
			>
				Save Configuration
			</StickSaveButton>
		</form>
	) : (
		innerForm
	);
};

const SftpIntegration: FC = () => {
	const { data: sftpConfig, loading } = useSftpConfig();
	const { data: flagValues, error: flagError, loading: flagLoading } = useFeatureFlags(B2B_SFTP_REDESIGN);
	const flagsLoaded = !flagLoading && !flagError && flagValues;
	const shouldUseRedesign = Boolean(flagsLoaded && flagValues && flagValues[B2B_SFTP_REDESIGN] === true);

	if (loading || !sftpConfig || flagLoading) {
		return <Loader color="gray1" />;
	}

	return <SftpIntegrationInner sftpConfig={sftpConfig} shouldUseRedesign={shouldUseRedesign} />;
};

export default SftpIntegration;
