import { ReactElement, ReactNode, SyntheticEvent, useMemo, useState, FC } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';

import { Card, Button } from '@calm-web/design-system';

import CellTitle from '@/components/ui/CellTitle';
import ConfirmModal from '@/components/ui/ConfirmModal';
import SocialShareButtons from '@/components/ui/SocialShareButtons';
import Table from '@/components/ui/Table';
import { EnvConfig } from '@/env_config';
import { useApi } from '@/hooks/api';
import { useInvite } from '@/hooks/api/useInvite';
import { PartnerMilestoneType, useRecordMilestone } from '@/hooks/api/useMilestones';
import { useDefinedPartner } from '@/hooks/api/usePartner';
import { usePartnerAdmin } from '@/hooks/api/usePartnerAdmin';
import { usePermissions } from '@/hooks/auth';
import { useIsMobile } from '@/hooks/layout/useIsMobile';
import { useUser } from '@/hooks/store';
import { useCanAddPhiAdmin } from '@/hooks/useCanAddPhiAdmin';
import { setBannerMessage } from '@/store/actions';
import { Admin } from '@/types/admin';
import { PartnerCategoryType } from '@/types/store/reducers';
import { partnerRoles, isPartnerAdmin, isAccountManager, isPHIAuthorized } from '@/utils/RBAC';
import { isCalmHealthProductSKU } from '@/utils/SkuUtils';
import { encodeObject } from '@/utils/base64ObjectUtils';
import { calmLogger } from '@/utils/calmLogger';

import AddAdminModal from './AddAdminModal';
import messages from './messages';
import {
	AddTrialAdmin,
	CircleCheck,
	MobileRow,
	MobileDataColumn,
	MobileLabel,
	MobileDataWrapper,
	OptionRow,
	OptionsContainer,
	OptionTextButton,
	Overlay,
	RowElement,
	TableTitle,
	MobileRowWrapper,
	SectionHeader,
	CircleXIcon,
	TableHeaderWrapper,
	MarginBottomDiv,
} from './styles';

interface AdminRow {
	name: ReactNode;
	email: ReactNode;
	accepted: ReactNode;
	options: ReactNode;
	phiAuthorized?: ReactNode;
	role?: ReactNode;
}

interface Column {
	Header: JSX.Element | string;
	accessor: keyof AdminRow;
	width?: number | string;
	minWidth?: number;
}

interface MobileAdminRowProps {
	admin: Admin;
	hasSalesTrialAdmin: boolean;
	openOption: (e: SyntheticEvent) => void;
	hideOptions: (e: SyntheticEvent) => void;
	handleAdminOption: (
		e: SyntheticEvent,
		action: string,
		email?: string,
		firstName?: string,
		lastName?: string,
		isPHIAuthorized?: boolean,
	) => Promise<void>;
	isSalesTrial: boolean;
	isCalmHealth?: boolean;
	isViewOnly?: boolean;
	canGrantPHI: boolean;
}

function getTrialSignupUrl(partnerId: string): string {
	const encodedSignupData = encodeObject({
		partner_id: partnerId,
	});
	// We send the signup data as calmjwt even though it isn't so that we can match the params
	// expect on the Reset Password / Signup pages
	const signupUrl = `${EnvConfig.partnerPortalUrl}/trial-signup?calmjwt=${encodedSignupData}`;
	return signupUrl;
}

const MobileAdminRow: FC<MobileAdminRowProps> = ({
	admin,
	hasSalesTrialAdmin,
	openOption,
	hideOptions,
	handleAdminOption,
	isSalesTrial,
	isCalmHealth,
	isViewOnly = false,
	canGrantPHI,
}) => {
	const { formatMessage } = useIntl();
	const { firstName, lastName, email } = admin;
	const { user } = useUser();
	const [hasValidPermissions, actions] = usePermissions();

	const userIsPartnerAdmin = isPartnerAdmin(user.accessPolicy?.allowed_user_role);
	const isAdmin = isAccountManager(user.accessPolicy?.allowed_user_role);
	const isTrialAdmin = admin?.allowedUserRole === partnerRoles.salesTrialAdmin;
	const adminIsPHIAuthorized = isPHIAuthorized(admin.allowedUserRole);

	return (
		<MobileRow>
			<MobileDataWrapper>
				<MobileDataColumn>
					<MobileLabel>Name</MobileLabel>
					<span>
						{firstName} {lastName}
					</span>
				</MobileDataColumn>
				<MobileDataColumn>
					<MobileLabel>Email</MobileLabel>
					<span>{email}</span>
				</MobileDataColumn>
			</MobileDataWrapper>
			<MobileDataWrapper>
				<MobileDataColumn>
					<MobileLabel>Accepted</MobileLabel>
					<RowElement centerAlign>{admin?.accepted && <CircleCheck />}</RowElement>
				</MobileDataColumn>
				{isCalmHealth && (
					<MobileDataColumn>
						<MobileLabel>Reporting Enabled</MobileLabel>
						<RowElement centerAlign>{adminIsPHIAuthorized ? <CircleCheck /> : <CircleXIcon />}</RowElement>
					</MobileDataColumn>
				)}
				{hasSalesTrialAdmin && (
					<MobileDataColumn>
						<MobileLabel>Role</MobileLabel>
						<div>
							{isTrialAdmin ? formatMessage(messages.salesTrialAdmin) : formatMessage(messages.admin)}
						</div>
					</MobileDataColumn>
				)}
			</MobileDataWrapper>
			{!isViewOnly && (
				<MobileDataColumn>
					<MobileDataColumn>
						<RowElement rightAlign>
							<OptionTextButton type="button" onClick={openOption}>
								{formatMessage(messages.optionsButton)}
							</OptionTextButton>

							<OptionsContainer>
								<Overlay onClick={hideOptions} />
								{!admin?.accepted && (
									<OptionRow
										onClick={(e): Promise<void> => handleAdminOption(e, 'invite', email, firstName, lastName)}
									>
										{formatMessage(messages.resendInvite)}
									</OptionRow>
								)}
								{admin?.accepted && (
									<OptionRow
										onClick={(e): Promise<void> => handleAdminOption(e, 'reset', email, firstName, lastName)}
									>
										{formatMessage(messages.passwordReset)}
									</OptionRow>
								)}
								{hasValidPermissions('admin_users', [actions.DELETE]) && (
									<OptionRow
										onClick={(e): Promise<void> => handleAdminOption(e, 'remove', email, firstName, lastName)}
									>
										{formatMessage(messages.removeAdmin)}
									</OptionRow>
								)}
								{!isSalesTrial && (isAdmin || userIsPartnerAdmin) && isTrialAdmin && (
									<OptionRow
										onClick={(e): Promise<void> =>
											handleAdminOption(e, 'promote', email, firstName, lastName)
										}
									>
										Make full admin
									</OptionRow>
								)}
								{isCalmHealth &&
									hasValidPermissions('admin_phi', [actions.UPDATE]) &&
									(canGrantPHI || adminIsPHIAuthorized) &&
									user.email !== email && (
										<OptionRow
											onClick={(e): Promise<void> =>
												handleAdminOption(e, 'authorizePHI', email, firstName, lastName, adminIsPHIAuthorized)
											}
										>
											{adminIsPHIAuthorized ? 'Remove' : 'Grant'} Reporting Permissions
										</OptionRow>
									)}
								{hasValidPermissions('reset_mfa', [actions.DELETE]) && (
									<OptionRow
										onClick={(e): Promise<void> =>
											handleAdminOption(e, 'resetMFA', email, firstName, lastName, adminIsPHIAuthorized)
										}
									>
										Reset MFA
									</OptionRow>
								)}
							</OptionsContainer>
						</RowElement>
					</MobileDataColumn>
				</MobileDataColumn>
			)}
		</MobileRow>
	);
};

export default function AdminUsers({
	category,
	isViewOnly = false,
}: {
	category: PartnerCategoryType;
	isViewOnly?: boolean;
}): ReactElement {
	const { formatMessage } = useIntl();
	const { partnerId } = useParams();
	const isSalesTrial = category === PartnerCategoryType.SALES_TRIAL;
	const { hash } = useLocation();
	const {
		data: admins = [],
		loading,
		error,
		removeAdminUser,
		promoteToAdminUser,
		updatePHIAuthorization,
		resetAdminMFA,
	} = usePartnerAdmin(partnerId);
	const partner = useDefinedPartner();
	const isCalmHealth = isCalmHealthProductSKU(partner?.product_sku);
	const apiRequest = useApi();
	const dispatch = useDispatch();
	const { user } = useUser();
	const [hasValidPermissions, actions] = usePermissions();
	const openInviteOnLoad = hash === '#invite-admin';
	const [showAdminModal, setShowAdminModal] = useState(openInviteOnLoad);
	const [mfaToReset, setMFAtoReset] = useState('');
	const [sendInviteEmail] = useInvite(partnerId);
	const [recordMilestone] = useRecordMilestone();
	const signupUrl = getTrialSignupUrl(partnerId);
	const [isMobile] = useIsMobile();
	const canGrantPHI = useCanAddPhiAdmin(admins, isCalmHealth);

	const hasSalesTrialAdmin = admins.some(
		// Note: `Sales Trial Admins` don't count as full admin roles.
		(admin: Admin) => admin.allowedUserRole === partnerRoles.salesTrialAdmin,
	);
	const formattedData = generateRows(admins, hasSalesTrialAdmin);
	const columns: Column[] = useMemo(() => {
		const cols: Column[] = [
			{ Header: 'Name', accessor: 'name', width: '30%', minWidth: 110 },
			{ Header: 'Email', accessor: 'email', width: '30%', minWidth: 110 },
			{ Header: <TableHeaderWrapper>Accepted</TableHeaderWrapper>, accessor: 'accepted', width: '10%' },
		];

		if (hasSalesTrialAdmin) {
			cols.push({ Header: 'Role', accessor: 'role', width: '15%' });
		}
		if (isCalmHealth) {
			cols.push({
				Header: <TableHeaderWrapper>Reporting</TableHeaderWrapper>,
				accessor: 'phiAuthorized',
				width: '10.6%',
			});
		}
		if (!isViewOnly) {
			cols.push({ Header: 'Actions', accessor: 'options', minWidth: 100 });
		}

		return cols;
	}, [hasSalesTrialAdmin, isCalmHealth, isViewOnly]);

	function openOption(e: SyntheticEvent): void {
		const optionsContainer = (e.target as HTMLDivElement).nextSibling as Element;
		// eslint-disable-next-line no-unused-expressions
		optionsContainer?.classList?.add('show-container');
	}

	function hideOptions(e: SyntheticEvent): void {
		const parent = (e.target as HTMLDivElement).parentElement as Element;
		// eslint-disable-next-line no-unused-expressions
		parent?.classList?.remove('show-container');
	}

	// TODO: Move this to a shared util file somewhere
	async function sendResetEmail(email: string): Promise<void> {
		try {
			await apiRequest({
				endpoint: 'partnerportal/users/request_reset_password',
				method: 'POST',
				body: { email },
			});
			dispatch(
				setBannerMessage({
					message: `Reset password email sent to ${email}`,
					isError: false,
					flash: true,
				}),
			);
		} catch (err) {
			dispatch(
				setBannerMessage({
					message: 'Failed to send reset password email',
					isError: true,
					flash: true,
				}),
			);
		}
	}

	async function handleAdminOption(
		e: SyntheticEvent,
		action: string,
		email?: string,
		firstName?: string,
		lastName?: string,
		isPHIAuthorized?: boolean,
	): Promise<void> {
		if (!email) return;
		hideOptions(e);
		// Grab the target since async actions will remove the event listener
		switch (action) {
			default:
			case 'action':
				await sendInviteEmail(email, firstName ?? '', lastName ?? '', isPHIAuthorized ?? false);
				break;
			case 'reset':
				await sendResetEmail(email);
				break;
			case 'remove':
				await removeAdminUser(email, partnerId);
				break;
			case 'promote':
				await promoteToAdminUser(email, partnerId);
				break;
			case 'authorizePHI':
				await updatePHIAuthorization(email, partnerId, !isPHIAuthorized);
				break;
			case 'resetMFA':
				setMFAtoReset(email);
				break;
		}
	}

	// Since this breaks most of the styling, we don't use the Calm Select component
	// from our components/ui folder
	// Only show the signup if they haven't signed up, and the reset password if they have signed up
	function generateRows(adminData: Array<Admin>, hasSalesTrialAdmin: boolean): Array<AdminRow> {
		const isAdmin = isAccountManager(user.accessPolicy?.allowed_user_role);
		const userIsPartnerAdmin = isPartnerAdmin(user.accessPolicy?.allowed_user_role);

		return adminData
			.sort((a: Admin, b: Admin) => a.email?.localeCompare(b?.email ?? '') ?? 0)
			.map((admin: Admin): AdminRow => {
				const { firstName, lastName, email } = admin;
				const isTrialAdmin = admin?.allowedUserRole === partnerRoles.salesTrialAdmin;
				const adminIsPHIAuthorized = isPHIAuthorized(admin.allowedUserRole);
				const row: AdminRow = {
					name: (
						<RowElement>
							<span>
								{firstName} {lastName}
							</span>
						</RowElement>
					),
					email: (
						<RowElement>
							<span>{email}</span>
						</RowElement>
					),
					accepted: (
						<RowElement centerAlign>
							{admin?.accepted && <CircleCheck data-testid="circle-check" />}
						</RowElement>
					),
					phiAuthorized: (
						<RowElement centerAlign>
							{adminIsPHIAuthorized ? (
								<CircleCheck data-testid="circle-check" />
							) : (
								<CircleXIcon data-testid="circle-x" />
							)}
						</RowElement>
					),
					options: (
						<RowElement rightAlign>
							<OptionTextButton type="button" onClick={openOption}>
								{formatMessage(messages.optionsButton)}
							</OptionTextButton>
							<OptionsContainer>
								<Overlay onClick={hideOptions} />
								{!admin?.accepted && (
									<OptionRow
										onClick={(e): Promise<void> =>
											handleAdminOption(e, 'invite', email, firstName, lastName, adminIsPHIAuthorized)
										}
									>
										{formatMessage(messages.resendInvite)}
									</OptionRow>
								)}
								{admin?.accepted && (
									<OptionRow
										onClick={(e): Promise<void> => handleAdminOption(e, 'reset', email, firstName, lastName)}
									>
										{formatMessage(messages.passwordReset)}
									</OptionRow>
								)}
								{hasValidPermissions('admin_users', [actions.DELETE]) && (
									<OptionRow
										onClick={(e): Promise<void> => handleAdminOption(e, 'remove', email, firstName, lastName)}
									>
										{formatMessage(messages.removeAdmin)}
									</OptionRow>
								)}
								{!isSalesTrial && (isAdmin || userIsPartnerAdmin) && isTrialAdmin && (
									<OptionRow
										onClick={(e): Promise<void> =>
											handleAdminOption(e, 'promote', email, firstName, lastName)
										}
									>
										Make full admin
									</OptionRow>
								)}
								{isCalmHealth &&
									hasValidPermissions('admin_phi', [actions.UPDATE]) &&
									(canGrantPHI || adminIsPHIAuthorized) &&
									user.email !== email && (
										<OptionRow
											onClick={(e): Promise<void> =>
												handleAdminOption(e, 'authorizePHI', email, firstName, lastName, adminIsPHIAuthorized)
											}
										>
											{adminIsPHIAuthorized ? 'Remove' : 'Grant'} Reporting Permissions
										</OptionRow>
									)}
								{hasValidPermissions('reset_mfa', [actions.DELETE]) && (
									<OptionRow
										onClick={(e): Promise<void> =>
											handleAdminOption(e, 'resetMFA', email, firstName, lastName, adminIsPHIAuthorized)
										}
									>
										Reset MFA
									</OptionRow>
								)}
							</OptionsContainer>
						</RowElement>
					),
				} as AdminRow;

				if (hasSalesTrialAdmin) {
					row.role = (
						<RowElement>
							<div>
								{isTrialAdmin ? formatMessage(messages.salesTrialAdmin) : formatMessage(messages.admin)}
							</div>
						</RowElement>
					);
				}
				return row;
			});
	}

	return (
		<Card noPadding>
			{isMobile ? (
				<MobileRowWrapper data-testid="admin-users-mobile-wrapper">
					<SectionHeader>
						<TableTitle>
							<CellTitle>
								{formatMessage(messages.title, {
									numAdmins: admins.length,
								})}
							</CellTitle>

							{isSalesTrial && (
								<AddTrialAdmin>
									<SocialShareButtons
										event={'Admin Signup'}
										url={signupUrl}
										showLinkedin={false}
										showTwitter={false}
										showFacebook={false}
										showEmail={false}
										showGmail={false}
										afterCopy={() => {
											recordMilestone({ eventName: PartnerMilestoneType.ADMIN_INVITED, partnerId }).catch(
												err => {
													calmLogger.error('Error when trying to log in to record milestone', {}, err);
												},
											);
										}}
									/>
								</AddTrialAdmin>
							)}
							{!isViewOnly && hasValidPermissions('admin_users', [actions.CREATE]) && (
								<Button
									backgroundColor="blue3"
									onPress={() => setShowAdminModal(true)}
									type="button"
									data-appcues="invite-admin"
								>
									{formatMessage(messages.newAdminButton)}
								</Button>
							)}
						</TableTitle>
					</SectionHeader>
					{admins
						.sort((a: Admin, b: Admin) => a.email?.localeCompare(b?.email ?? '') ?? 0)
						.map((admin: Admin) => (
							<MobileAdminRow
								key={admin.email}
								admin={admin}
								hasSalesTrialAdmin={hasSalesTrialAdmin}
								openOption={openOption}
								hideOptions={hideOptions}
								handleAdminOption={handleAdminOption}
								isSalesTrial={isSalesTrial}
								isCalmHealth={isCalmHealth}
								isViewOnly={isViewOnly}
								canGrantPHI={canGrantPHI}
							/>
						))}
				</MobileRowWrapper>
			) : (
				<Table
					columns={columns}
					data={formattedData}
					loading={loading}
					pageCount={1}
					error={error?.message}
					alignHeadingsToText
					cellVerticalAlign="middle"
				>
					<TableTitle>
						<CellTitle>
							{formatMessage(messages.title, {
								numAdmins: admins.length,
							})}
						</CellTitle>

						{isSalesTrial && (
							<AddTrialAdmin>
								<SocialShareButtons
									event={'Admin Signup'}
									url={signupUrl}
									showLinkedin={false}
									showTwitter={false}
									showFacebook={false}
									showEmail={false}
									showGmail={false}
									afterCopy={() => {
										recordMilestone({ eventName: PartnerMilestoneType.ADMIN_INVITED, partnerId }).catch(
											err => {
												calmLogger.error('Error when trying to log in to record milestone', {}, err);
											},
										);
									}}
								/>
							</AddTrialAdmin>
						)}
						{!isViewOnly && hasValidPermissions('admin_users', [actions.CREATE]) && (
							<Button
								backgroundColor="blue3"
								onPress={() => setShowAdminModal(true)}
								type="button"
								data-appcues="invite-admin"
							>
								{formatMessage(messages.newAdminButton)}
							</Button>
						)}
					</TableTitle>
				</Table>
			)}
			{showAdminModal && (
				<AddAdminModal
					partnerId={partnerId}
					showAdminModal={showAdminModal}
					setShowAdminModal={setShowAdminModal}
					isCalmHealth={isCalmHealth}
				/>
			)}
			<ConfirmModal
				title={`Reset ${mfaToReset}'s MFA Device?`}
				confirmText="Reset MFA Device"
				confirmTextBackgroundColor="blue3"
				description={
					<>
						<MarginBottomDiv>
							You are about to reset Multi-Factor Authentication (MFA) for {mfaToReset}. Access codes on the
							device they are currently using and recovery codes that they have saved will no longer be
							accepted for authentication. This user will be asked to set up MFA on their device again next
							time they log in.
						</MarginBottomDiv>
						<div>
							Ensure that you are not a subject of a phishing scam and that the request for MFA reset came
							from this user through a reliable source.
						</div>
					</>
				}
				isOpen={!!mfaToReset}
				onConfirm={async () => {
					setMFAtoReset('');
					await resetAdminMFA(mfaToReset);
				}}
				onCancel={() => {
					setMFAtoReset('');
				}}
			/>
		</Card>
	);
}
